import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import FeaturedServices from '../components/FeaturedServices';
import ElevatorPitch from '../components/ElevatorPitch';
import BeforeAfter from '../components/BeforeAfter';
import ServiceAreasOverview from '../components/ServiceAreasOverview';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "trucks-hero.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beforeImage: file(relativePath: { eq: "IMG_1432.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afterImage: file(relativePath: { eq: "IMG_1534After.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Commercial Masonry Contractors Chicago"
        description="Mad Masonry stands apart from other masonry contractors in Chicago thanks to our service and capabilities. Learn more by clicking here."
      />
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Mad Masonry",
            "url": "https://masonfix.com/",
            "logo": "https://masonfix.com/img/Mad-Masonry-Logo.svg",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "2655 Davisson St",
              "addressLocality": "River Grove",
              "addressRegion": "IL",
              "postalCode": "60171",
              "addressCountry": {
                "@type": "Country",
                "name": "USA"
              }
            }
          }`}
        </script>
      </Helmet>
      <Container>
        <Hero image={data.heroImage.childImageSharp.fluid} />
        <FeaturedServices className="mb-2" />
        <ElevatorPitch className="my-5" />
        <BeforeAfter
          before={data.beforeImage}
          after={data.afterImage}
          className="my-5"
        />
        <ServiceAreasOverview />
      </Container>
    </Layout>
  );
};

export default IndexPage;
