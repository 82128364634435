import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';

const BeforeAfter = ({ className, before, after }) => {
  return (
    <section className={className}>
      <Container>
        <Row>
          <Col xs="12" md="6" className="mb-4 mb-md-0">
            <div className="text-uppercase text-center font-weight-bold mb-3 text-muted">
              Before
            </div>
            <Img fluid={before.childImageSharp.fluid} />
          </Col>
          <Col xs="12" md="6">
            <div className="text-uppercase text-center font-weight-bold mb-3 text-muted">
              After
            </div>
            <Img fluid={after.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

BeforeAfter.propTypes = {
  className: PropTypes.string,
  before: PropTypes.object,
  after: PropTypes.object,
};

export default BeforeAfter;
