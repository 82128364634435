import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';
import decode from '../util/decodeEntities';
import '../styles/featured-services.scss';

const FeaturedServices = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      grindingTuckPointing: file(
        relativePath: { eq: "grinding-tuckpointing.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      allWordpressPage {
        edges {
          node {
            status
            title
            slug
            featured_media {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 250, maxHeight: 250) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const featuredServiceSlugs = [
    'tuckpointing',
    'steel-lintel-i-beam-replacement',
    'masonry-restoration',
    'masonry-sealing-waterproofing',
  ];

  const featuredServices = data.allWordpressPage.edges.filter(edge => {
    return featuredServiceSlugs.includes(edge.node.slug);
  });

  // Featured Service component
  const FeaturedService = ({ title, fluid, to }) => {
    return (
      <Col xs={12} sm={6} lg={3} className="featured-service">
        <Link to={to} className="mb-4 letter-spacing-1">
          <span>{title}</span>
          <Img fluid={fluid} />
        </Link>
      </Col>
    );
  };

  FeaturedService.propTypes = {
    title: PropTypes.string,
    to: PropTypes.string,
    fluid: PropTypes.object,
  };

  // Default: FeaturedServices
  return (
    <section className={`featured-services-section ${className}`}>
      <Container>
        <Row>
          {featuredServices.map(fs => {
            const slug = '/services/' + fs.node.slug;
            const title = decode(fs.node.title);
            const fluid = fs.node.featured_media
              ? fs.node.featured_media.localFile.childImageSharp.fluid
              : data.grindingTuckPointing.childImageSharp.fluid;
            return (
              <FeaturedService
                key={slug}
                to={slug}
                title={title}
                fluid={fluid}
              />
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

FeaturedServices.propTypes = {
  className: PropTypes.string,
};

export default FeaturedServices;
