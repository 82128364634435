import React from 'react';
// import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'react-bootstrap';

const ElevatorPitch = () => {
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary font-weight-bold mb-3">
            Masonry Contractors In Chicago And Beyond
          </h1>
          <h2 className="text-primary font-weight-bold mb-3">
            Mad Masonry - Get The Job Done Right!
          </h2>
          <p>
            Mad Masonry has been around since 1999 in the Chicagoland area. We
            provide masonry repair, restoration and other damage-related repair
            services. Our services are available for a variety of situations,
            and we also offer our services for fixing the damage that has been
            caused by flawed construction.
          </p>
          <p>
            Hiring professional commercial masonry contractors that have
            extensive expertise and provide a wide range of services brings you
            the best results. No other masonry repair company in the area has
            the level of customer service and experience that we do, and we’re
            eager to show you why our masonry repair and restoration services
            stand apart from the competition.
          </p>
          <Button href="/about-us" className="text-uppercase font-weight-bold">
            More About Us
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

ElevatorPitch.propTypes = {};

export default ElevatorPitch;
