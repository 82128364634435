import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

const ServiceAreasOverview = ({ className }) => {
  return (
    <section className={className}>
      <div className="bg-primary text-white font-weight-bolder text-center p-3">
        Serving Illinois, Indiana, Wisconsin
      </div>
      <Container className="py-3 py-md-5 px-md-5">
        <Row>
          <Col>
            <p>
              Mad Masonry has worked on projects in every neighborhood in
              Chicago and every town, village and city in the Chicagoland area.
              We also cover part of Wisconsin, Indiana & parts of Florida.
            </p>
            <p>
              We regularly work in Barrington, Buffalo Grove, Clarendon Hills,
              Des Plaines, Hinsdale, Libertyville, Long Grove, Naperville, Park
              Ridge, Western Springs
            </p>
            <p>
              On the North Shore, we work regularly in Deerfield, Evanston,
              Glencoe, Glenview, Highland Park, Highwood, Kenilworth, Lake
              Bluff, Lake Forest, Morton Grove, Niles, Northbrook, Northfield,
              Skokie, Wilmette, and Winnetka.
            </p>
            <p>
              To the west of Chicago, we enjoy working in Oak Park, River
              Forest, Riverside, and LaGrange, Tinley Park, Orland Park
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

ServiceAreasOverview.propTypes = {
  className: PropTypes.string,
};

export default ServiceAreasOverview;
